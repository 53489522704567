import { useEffect, useState } from "react";
import ReactiveButton from 'reactive-button';



import ActiveCallDetail from "./components/ActiveCallDetail";

import Vapi from "@vapi-ai/web";
import { isPublicKeyMissingError } from "./utils";
import RachelAIImage from "./images/RachelAI.png";

// Put your Vapi Public Key below.
const vapi = new Vapi("f825d37d-fd1f-41e8-afc5-d798fd83fbc3");

const App = () => {

  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);

  const { showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage } = usePublicKeyInvalid();

  // hook into Vapi events
  useEffect(() => {
    vapi.on("call-start", () => {
      setConnecting(false);
      setConnected(true);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("call-end", () => {
      setConnecting(false);
      setConnected(false);

      setShowPublicKeyInvalidMessage(false);
    });

    vapi.on("speech-start", () => {
      setAssistantIsSpeaking(true);
    });

    vapi.on("speech-end", () => {
      setAssistantIsSpeaking(false);
    });

    vapi.on("volume-level", (level) => {
      setVolumeLevel(level);
    });

    vapi.on("error", (error) => {
      console.error(error);

      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        setShowPublicKeyInvalidMessage(true);
      }
    });

    // we only want this to fire on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // call start handler
  const startCallInline = () => {
    setConnecting(true);
    vapi.start("55496f8e-60d6-4173-b001-32b2697a618c");
  };
  const endCall = () => {
    vapi.stop();
  };
  

  return (
    <div
      style={{

        width: "100vw",
        height: "100vh",
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}
    >
      {!connected ? (
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <img
            src={RachelAIImage}
            alt=""
            style={{
              width: "120px",
              height: "120px",
              marginBottom: '20px'
            }}
          />
          <ReactiveButton
            rounded
            idleText={
              <span>
                Speak with Ahmed's Recruiting Assistant, Rachel AI
              </span>
            }
            onClick={startCallInline}
            isLoading={connecting}
            color="primary"
            size="medium"
            style={{
              alignSelf: 'center' // Specifically align the button itself
            }}
          />
        </div>
      ) : (
        <ActiveCallDetail
          assistantIsSpeaking={assistantIsSpeaking}
          volumeLevel={volumeLevel}
          onEndCallClick={endCall}
        />
      )}
      {showPublicKeyInvalidMessage ? <PleaseSetYourPublicKeyMessage /> : null}
      <ReturnToDocsLink />
      {/* <div class="wave"></div> */}
    </div>
  );
};


const usePublicKeyInvalid = () => {
  const [showPublicKeyInvalidMessage, setShowPublicKeyInvalidMessage] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const PleaseSetYourPublicKeyMessage = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "25px",
        left: "25px",
        padding: "10px",
        color: "#fff",
        backgroundColor: "#f03e3e",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Is your Vapi Public Key missing? (recheck your code)
    </div>
  );
};

const ReturnToDocsLink = () => {
  return (
    <a
      href="https://ahmedkhan25.carbonmade.com/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        position: "fixed",
        top: "25px",
        right: "25px",
        padding: "5px 10px",
        color: "#fff",
        textDecoration: "none",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
      }}
    >
      Ahmed Khan's Portfolio
    </a>
  );
};

export default App;

/*
 <span>
 <FontAwesomeIcon icon={faRobot} /> Speak with Ahmed's Recruiting Assistant, Rachal AI
</span>
*/